import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { ArmazenamentoService } from '../servicos/armazenamento/armazenamento.service'

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private armazenamentoS: ArmazenamentoService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
    {
        let token

        try {
            let usuarioLogado = JSON.parse(this.armazenamentoS.obterItem('LRLogU'))
            token = usuarioLogado['token']
        } catch (error) {
            token = ''
        }

        if (token != '') {
            return true
        }

        this.router.navigate(['/login'])
        return false
    }

}
