import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig } from './app/compartilhados/classes/app.config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  AppConfig.definirUrlLRApi('https://api.rematefy.com')
  AppConfig.definirUrlLRSig('https://api.rematefy.com')
} else {
  AppConfig.definirUrlLRApi('https://api-hml.rematefy.com')
  AppConfig.definirUrlLRSig('https://socket-server-rfy-hml.azurewebsites.net')
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
