import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { Observable, throwError } from 'rxjs'
import { retry, catchError } from 'rxjs/operators'

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(public toastrS: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if( request.url.includes('/favoritos/produtores') || 
            request.url.includes('/Auditoria/Vendas/Status') ||
            request.url.includes('/Pessoas/documento')
        ){
            return  next.handle(request);
        }

        return next.handle(request)
            .pipe(
                // retry(1),
                catchError((error: HttpErrorResponse) => {
                    if (error.error instanceof ErrorEvent) {
                        // Erro no client
                        this.toastrS.error(error.error.message)
                        return throwError(error.error.message)
                    } else {
                        // Erro no servidor
                        switch (error.error.status) {
                            case 0: {
                                let msg = 'Falha na comunicação. Verifique sua conexão e tente novamente.'
                                this.toastrS.error(msg)
                                return throwError(error.error.errors)
                            }
                            case 400: {
                                let chaves = Object.keys(error.error.errors)
                                chaves.forEach(c => {
                                    error.error.errors[c].forEach(e => {
                                        this.toastrS.error(e)
                                    })
                                })
                                
                                return throwError(error.error.errors)
                            }
                            case 403: {
                                let msg = 'Você não tem permissão para realizar esta operação'
                                this.toastrS.error(msg)
                                
                                return throwError(msg)
                            }
                            case 404: {
                                this.toastrS.error('Nenhum registro encontrado')
                                return throwError('')
                            }
                            default: {
                                if (Array.isArray(error.error.errors)) {
                                    error.error.errors.forEach(e => {
                                        this.toastrS.error(e)
                                        error.error.message = e
                                    })
                                } else if (error.error.message) {
                                    this.toastrS.error(error.error.message)
                                } else {
                                    if(error.status === 0){
                                        let msg = 'Falha na comunicação. Verifique sua conexão e tente novamente.'
                                        this.toastrS.error(msg)
                                    }else{
                                        this.toastrS.error(error.error)
                                    }
                                }

                                return throwError(error.error.message)
                            }
                        }
                    }
                })
            )
    }
}