import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './compartilhados/servicos/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private authS: AuthService,
        private router: Router
    ) {
        router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                if (e.url === '/') {
                    this.router.navigate(['/dashboard'])
                }
            }
        })
    }

    ngOnInit() {
        this.authS.renovarToken()
    }

    
}
