import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { AppConfig } from '../../classes/app.config'
import { ArmazenamentoService } from '../armazenamento/armazenamento.service'
import { UsuarioLogadoService } from '../usuarioLogado/usuario-logado.service'
import { UsuarioLogado } from '../../classes/usuario-logado'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private urlLRApi = AppConfig.obterUrlLRApi()
    private usuarioLogado: UsuarioLogado
    private tempoRenovacaoToken = 15

    constructor(
        private armazenamentoS: ArmazenamentoService,
        private httpS: HttpClient,
        private usuarioLogadoS: UsuarioLogadoService
    ) { 
        this.usuarioLogado = this.usuarioLogadoS.obterUsuarioLogado()

        this.usuarioLogadoS.usuarioEmitter.subscribe(
            () => {
                this.usuarioLogado = this.usuarioLogadoS.obterUsuarioLogado()
            }
        )
    }

    alterarURL(){
        if(AppConfig.obterUrlLRApi() == "https://api-hml.rematefy.com"){
            AppConfig.definirUrlLRApi('https://api-hml2.rematefy.com.br');
        }else{
            AppConfig.definirUrlLRApi("https://api-hml.rematefy.com");
        }
        this.urlLRApi = AppConfig.obterUrlLRApi()
    }

    definirUsuarioLogado = (usuarioLogado, manterLogado = null) => {
        if (manterLogado) {
            localStorage.setItem('modalidadeLogin', manterLogado ? 'local' : 'session')
        }
        this.armazenamentoS.definirItem('LRLogU', JSON.stringify(usuarioLogado))
        this.usuarioLogadoS.carregarUsuarioLogado()
    }

    login = (username, password) => {
        let req = { username, password }

        return this.httpS.post(this.urlLRApi + '/api/v1/Auth/login', req)
            .pipe(map(res => {
                this.definirUsuarioLogado(res['data'])
                this.iniciarTimerRenovarToken()
                return res
            }))
    }

    logout = () => {
        this.pararTimerRenovarToken()
        /*
        *   Alterado .clear() para .remover(), afim de limpar apenas dados de segurança,
        *   e manter dados de cache
        * 
        * localStorage.clear()
        * sessionStorage.clear()
        */
        localStorage.removeItem('modalidadeLogin')
        localStorage.removeItem('LRLogU')
        sessionStorage.removeItem('modalidadeLogin')
        sessionStorage.removeItem('LRLogU')

        location.reload()
    }

    private obterTempoRestanteToken = () => {
        const expiracao = new Date(this.usuarioLogado.expiresIn)
        const dataAtual = new Date()
        let timeout = Math.abs(expiracao.getTime() - dataAtual.getTime()) / 1000

        return expiracao.getTime() > dataAtual.getTime()
            ? timeout
            : -timeout
    }

    redefinirSenha = (email) => {
        return this.httpS.post(this.urlLRApi + `/api/v1/User/ForgotPassword?email=${email}`, null)
    }
    
    renovarToken = () => {
        let req: any
        
        // Se a tentativa de pegar os dados do usuário logado gerar uma exceção,
        // signigica que o usuário não estava logado. Portanto não seguirá em frente
        try {
            req = {
                username: this.usuarioLogadoS.obterUsuarioLogado().userToken.username,
                refreshToken: this.usuarioLogadoS.obterUsuarioLogado().refreshToken
            }
        } catch (err) { 

        } finally {
            if (req) {
                if (this.obterTempoRestanteToken() < this.tempoRenovacaoToken) {
                    this.httpS.post(`${this.urlLRApi}/api/v1/Auth/refresh`, req, { 
                        headers: new HttpHeaders().set('Authorization', `bearer ${this.usuarioLogadoS.obterUsuarioLogado().accessToken}`)
                    }).subscribe(
                        res => {
                            this.definirUsuarioLogado(res['data'])
                            this.iniciarTimerRenovarToken()
                        }, err => {
                            this.logout()
                        }
                    )
                } else {
                    this.iniciarTimerRenovarToken()
                }
            }
        }
    }

    private renovarTokenTimeout
    private iniciarTimerRenovarToken = () => {
        const expiracao = new Date(this.usuarioLogado.expiresIn)
        const dataAtual = new Date()
        let timeout = Math.abs(expiracao.getTime() - dataAtual.getTime()) / 1000
        timeout = (timeout - this.tempoRenovacaoToken) * 1000 // A renovação iniciará antes da expiração do token
        this.renovarTokenTimeout = setTimeout(() => this.renovarToken(), timeout)
    }

    private pararTimerRenovarToken = () => {
        clearTimeout(this.renovarTokenTimeout)
    }
}
