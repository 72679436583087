import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AppHttpInterceptor } from './compartilhados/interceptors/http-error.interceptor'
import { registerLocaleData } from '@angular/common'
import localeBr from '@angular/common/locales/pt'
import { CookieService } from 'ngx-cookie-service';

registerLocaleData(localeBr)

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToastrModule.forRoot({
            progressBar: true,
            preventDuplicates: true,
            positionClass: 'toast-bottom-right'
        }),
        AppRoutingModule
    ],
    providers: [
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
