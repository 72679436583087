import { EventEmitter, Injectable } from '@angular/core';
import { UsuarioLogado } from '../../classes/usuario-logado';
import { ArmazenamentoService } from '../armazenamento/armazenamento.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UsuarioLogadoService {
    private usuario: UsuarioLogado
    usuarioEmitter = new EventEmitter()

    constructor(
        private armazenamentoS: ArmazenamentoService,
        private router: Router,
    ) {
        if(!this.router.url.includes('publico')){
            this.carregarUsuarioLogado()
        }
    }

    carregarUsuarioLogado = () => {
        this.usuario = JSON.parse(this.armazenamentoS.obterItem('LRLogU'))
        this.usuarioEmitter.emit()
    }

    obterUsuarioLogado = () => {
        return this.usuario
    }

    isLoginSindicato(){
        let status: boolean = false
        if (this.usuario.userToken.claims.length > 0){
            this.usuario.userToken.claims.forEach(c => {
                if (c.type == 'reference_sindicato'){
                    status = true
                }
            });   
        }
        return status
    }
}
