import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from './compartilhados/guardas/auth.guard'

const routes: Routes = [

    {
        path: '',
        loadChildren: () => import('./paginas/paginas.module').then(m => m.PaginasModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'publico',
        loadChildren: () => import('./publico/publico.module').then(m => m.PublicoModule),
    },
    {
        path: 'monitor',
        loadChildren: () => import('./monitores/monitores.module').then(m => m.MonitoresModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'utilitarios',
        loadChildren: () => import('./utilitarios/utilitarios.module').then(m => m.UtilitariosModule),
        canActivate: [AuthGuard]
    },
    // { 
    //     path: '404', 
    //     loadChildren: () => import('./404/not-found.module').then(m => m.NotFoundModule)
    // },
    { 
         path: '**', 
         redirectTo: 'dashboard' 
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
